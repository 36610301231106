@import "./Vars.scss";
@import "./Fonts.scss";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  position: relative;
  background: $white;
  overflow-x: hidden;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
  &:hover{
  }
  &:visited{
  }
}
img{
  width: 100%;
  height: auto;
  display: block;
}

.title{
  font-family: "Oswald";
  text-transform: uppercase;
  font-size: 15px;
  color: black;
}
textarea{
  line-height: 1.6;
  padding: 0 4px;
  border: 2px solid #000;
  border-radius: 4px;
  resize: none;
  flex: 0 1 100%;
}

.save-button{
  background: white;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 2px 8px;
}

.container{
  // width: calc(100% - 40px);
  // max-width: 1080px;
  // margin-left: auto;
  // margin-right: auto;
}

.text{
  font-family: "Open sans";
  font-size: 14px;
  color: black;
}

svg{
  display: block;
}

.file-input__text{
  background: #33b249;
  color: white;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.masonry{
  padding-bottom: 20px;

  display: flex;
  width: 100%;
  &__column{
    background-clip: padding-box;

    & + &{
      margin-left: 50px;
    }
    & > * + *{
      margin-top: 50px;
    }
  }
  &__item{
    display: block;
    cursor: pointer;
  }
}

.edit-button{
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 40px;

  width: 80px;
  height: 80px;

  font-size: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 5px solid darkred;
  color: darkred;
  border-radius: 10px;

  &[data-editing="true"]{
    border-color: darkgreen;
    color: darkgreen;
  }
}

.post-preview{
  position: relative;
  display: block;
  transition: transform 2s cubic-bezier(0.2,0.84,0.5,1);

  &:hover{
    .post-preview__name,
    .art__title{
      transform: translateY(0) skewY(0);
      transition: transform 0.4s, opacity 0.4s;
      
      opacity: 1;
    }
  }
  
  &__image{
    width: 100%;
    height: 100%;
    display: block;
  }
  &__name,
  .art__title{
    justify-content: start;
    position: relative;
    margin: 22px 0 12px;
    font-family: "Oswald";
    text-transform: uppercase;
    font-size: 15px;

    color: $black;

    transform: translateY(30px) skewY(-8deg);
    opacity: 0;
  }
  &__hover{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
  }
  &__remove-button{
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    border: 2px solid transparent; 
    border-radius: 4px;
    font-size: 30px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      border-color: $lightbrown;
      background: rgba($lightbrown, 0.4);
    }
  }
  &__text{
    display: inline-block;
    font-size: 20px;
    font-family: "Lato";
    color: $white;
    background: rgba($black, 0.3);
    padding: 20px;
  }
  &__order-button{
    padding: 10px;
    font-size: 30px;
    width: 50px;
    height: 50px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    
    &--left{
      
      left: 0;
    }
    &--right{
      right: 0;
    }
  }
}

.post {
  display: flex;
  justify-content: center;
  &__container{
    margin: 0 80px;
    width: 100%;
    display: flex;
    column-gap: 30px;
    padding-top: 40px;
    max-width: 1300px;
    
    @media (max-width: 1400px) {
      max-width: 1100px;
    }
    @media (max-width: 1200px) {
      max-width: 950px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 500px) {
      margin: 0 40px;
    }
  }
  &__image-wrapper{
    max-width: 1100px;
    flex: 0 0 66.67%;
  }
  &__main-image-block {

  }
  &__main-image {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
  
  &__image-block {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__text-block {
    flex: 0 0 33.33%;
    padding: 0 15px 0 15px;
    @media (max-width: 1024px) {
      padding: 30px 0 0;
      padding-top: 30px;
      flex-basis: auto;
    }
  }
  &__name {
    font-family: "Oswald";
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 8px;
  }
  &__text{
    white-space: pre-wrap;
    &.title{
      margin-top: 35px;
    }
    &.text{
      margin-top: 25px;
    }
  }
}

.edit-post__container{
  @media (max-width: 1000px) {
    flex-direction: column;
    row-gap: 60px;
  }
}