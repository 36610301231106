@font-face {
  font-family: "Nobel Book";
  src: url("../Fonts/Nobel-Book.ttf");
}
@font-face {
  font-family: "Input Mono";
  src: url("../Fonts/InputMono-Regular.ttf");
}
@font-face {
  font-family: "Jost";
  src: url("../Fonts/Jost-Medium.ttf");
}
@font-face {
  font-family: "Lato";
  src: url("../Fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Crimson Text";
  src: url("../Fonts/CrimsonText-Roman.ttf");
}
@font-face {
  font-family: "Telefon Normal";
  src: url("../Fonts/Telefon-Normal.ttf");
}

@font-face {
  font-family: "Oswald";
  src: url("../Fonts/Oswald-Bold.ttf");
}
@font-face {
  font-family: "Open sans";
  src: url("../Fonts/OpenSans-Regular.ttf");
}