@import './Fonts.scss';
@import './Vars.scss';

.contact{

  &__container{
    gap: 40px;
    margin: 0 30px;
  }
  &__text-block{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text{
    color: $black;
    font-family: "Open sans";
    font-size: 18px;

    white-space: pre-wrap;
    * + &{
      margin-top: 20px;
    }
  }
  &__link{
    color: $black;
    &:visited{
      color: $black;
    }
    &:hover{
      color: $lightbrown;
    }
  }

  &__svg{
    width: 32px;
    height: auto;
  }

  &__input{
    color: $grey;
    font-family: "Lato";
    font-size: 18px;
    
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    resize: vertical;
  }
  &__buttons{
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 100;
    button{
      display: inline-block;
      font-size: 20px;
      padding: 10px;
    }
  }
  &__textarea.text{
    font-size: 18px;
  }
}