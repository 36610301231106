.main{
  &__container{
    // padding-top: 60px;
    padding: 40px 6.25% 148px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1600px) {
      padding: 40px 7.25% 148px;
    }
    @media (max-width: 1024px) {
      padding: 40px 6.25% 48px;
    }
    @media (max-width: 680px) {
      padding: 25px 6.25% 48px
    }
  }
}