@import './Vars.scss';
@import './Fonts.scss';

.footer{
  padding: 0 0 50px;

  margin-top: auto;

  @media (max-width: 1024px) {
    padding: 40px 0 50px;
  }

  &__navigation{
    display: flex;
    
    max-width: 450px;
    margin: auto;

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    // column-gap: 20px;
    column-gap: 48px;
    row-gap: 30px;
  }

  &__text{
    margin-top: 27px;
    font-family: "Input Mono";
    font-size: 11px;
    color: $black;
    text-align: center;
  }
}